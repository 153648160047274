<template>
  <div class="has-text-centered">
    <Spinner v-show="!completed" message="JDoodle is loading your projects, please wait..." />
    <div v-show="completed && !success" class="success-message">Login Failed!</div>
    <div v-show="completed && success" class="success-message">Login Successful!</div>
    <div v-show="completed && success">
      <p class="open-message">
        To open the JDoodle app, click the button below.
      </p>
      <a class="button is-primary" href="/mobile-app/links/post-login/">Open App</a>
    </div>
  </div>
</template>

<script>
import formMixin from '@/assets/javascript/form-mixin'
import Spinner from '@/components/Utils/Spinner'

export default {
  name: 'mobileRegister',
  components: { Spinner },
  mixins: [formMixin],
  mounted() {
    this.onload()
  },
  data: function () {
    return {
      success: false,
      completed: false
    }
  },
  methods: {
    onload() {
      this.executeAPI({
        url: '/api/mobile/remme',
        method: 'post',
        data: {
          token: this.$route.params.token
        },
        successAction: (data) => {
          if (data.error) {
            this.doodleForm.errorMessage = data.error
            this.doodleForm.completed = false
          } else {
            this.success = true
          }
          this.completed = true
        },
        failureAction: () => {
          this.completed = true
        },
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GLOBAL,
        jdaEvent: 'login',
        jdaLabel: ''
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../node_modules/bulma-divider/dist/css/bulma-divider.min.css";
@import "../../assets/style/oauth";

.success-message {
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
}

.open-message {
  margin-bottom: 20px;
}
</style>
